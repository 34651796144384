import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo"
import { Link } from "gatsby";
import CTA from '../components/cta';
import Quote from '../components/quote';
import PortfolioFeed from '../components/portfolio-feed';

import designServiceHero from '../images/backrounds/design-services-hero.jpg';
import { StaticImage } from "gatsby-plugin-image";

const DesignService = ({location}) => (
  <Layout>
  <Seo
    pathName={location.pathname}
    title="Design Services"
    description="Partner with us for an all-in-one solution for website design consulting services, brand identity consultant services and UI/UX consulting services."
  />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${designServiceHero})`
        }}
      >
        <div className="hero-title bg-primary-transparent">
          <h1 className="text-white">
            729 Solutions Provides Design Services To Combine Form With Function
          </h1>
          <p className="text-white">
            729 is not just a development shop — we’ve got a full team of <Link className="text-secondary-light" to="/web-design-consulting-services/">designers as well</Link>. Every project should be beautifully designed and optimized for a smooth user experience.
          </p>
          <CTA href="/contact-us/" text="Let's Connect" className="btn btn-secondary-light"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <h2>What’s squelching your projects? Bureaucracy? Budget?</h2>
            <p>
              It’s easy to get bogged down by red tape and restrictive budgets.
            </p>
            <p>
              729 Solutions breaks through traditional barriers with our signature project management process. It’s how we deliver high-quality work at affordable rates, all with a friendly sense of humor you won’t find anywhere else.
            </p>
            <CTA href="/contact-us/" text="TELL US YOUR BIG IDEA" className="btn btn-primary"/>  
          </div>
          <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
            <StaticImage src="../images/backrounds/bureaucracy.jpg" alt="BUREAUCRACY" />
          </div>
        </div>
        <div className="row mt-6">
          <div className="col text-center">
            <h2>We hear you loud and clear.<br/>This is what we know</h2>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-3">
          <div className="col mb-4 mb-lg-0">
            <div className="card custom-cards text-left h-100">
              <div className="card-body">
                <h3>You need a <span className="d-lg-block">one-stop</span></h3>
                <p>
                  So that’s what we are.
                </p>
                <p>
                  We do it all, from user experience consulting to brand management. We’re truly an all-in-one solution.
                </p>
                <p>
                  Our design process will help discover and deliver exactly what you need.
                </p>          
              </div>
              <div className="card-footer bg-transparent border-0 pt-0 pb-4">
                <CTA href="/studios-design-process/" text="Learn More" className="btn btn-primary"/>    
              </div>
            </div>
          </div>
          <div className="col mb-4 mb-lg-0">
            <div className="card custom-cards text-left h-100">
              <div className="card-body">
                <h3>You don’t want downtime</h3>
                <p>
                  We get it. You want to stay productive. <Link className="d-lg-inline-block" to="/about-us/">Our company founder</Link> says, “I hated working in and around design shops who were time wasters or couldn’t actually do the work. They’d just draw the pictures and walk away.”
                </p>
                <p>
                  He founded 729 Solutions to offer a more productive and collaborative approach— an approach we’ll bring to every project we do with you.
                </p>
              </div>
            </div>
          </div>  
          <div className="col mb-4 mb-lg-0">
            <div className="card custom-cards text-left h-100">
              <div className="card-body">
                <h3>You value <span className="d-lg-block">your budget</span></h3>
                <p>
                  We can work with your budget to get the most out of your money.
                </p>
                <p>
                  Give us your bottom line, and we’ll deliver high-quality results that are always money well-spent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-primary">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-5">
            <h2 className="text-white mb-lg-0">WE CULTIVATE CREATIVITY WITH MEANINGFUL, MEASURABLE RESULTS</h2>
          </div>
          <div className="col-lg-7 text-white">
            <p>
              A successful project connects deeply with your target audience and furthers your business goals.
            </p>
            <p>
              We use our diverse team of nimble thinkers to identify your unique needs, develop customized solutions and absolutely delight your customers.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pt-6">
        <div className="row">
          <div className="col text-center">
            <h2>We don’t mean to brag, but we have some big-name partners</h2>
          </div>
        </div>
        <div className="row pb-md-5 px-md-6 d-flex justify-content-center align-items-center text-center">
          <div className="col-md-4 mb-4 mb-md-0">
            <a href="https://www.zendesk.com/" target="_blank" rel="noreferrer">
              <StaticImage src="../images/partners/zendesk-alt.png" alt="Zendesk" />
            </a>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
              <StaticImage src="../images/partners/aws-alt.png" alt="AWS" />
            </a>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <a href="https://github.com/" target="_blank" rel="noreferrer">
              <StaticImage src="../images/partners/github-alt.png" alt="Github" />
            </a>
          </div>
        </div>
        <div className="row px-md-6 d-flex justify-content-center align-items-center text-center">  
          <div className="col-md-4 mb-4 mb-md-0">
            <a href="https://asana.com/" target="_blank" rel="noreferrer">
              <StaticImage src="../images/partners/asana.png" alt="Asana" />
            </a>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <a href="https://www.pivotaltracker.com" target="_blank" rel="noreferrer">
              <StaticImage src="../images/partners/pivotal-tracker.png" alt="Pivotal Tracker" />
            </a>
          </div>
        </div>
      </div>

    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col text-center">
            <h2>Our services include:</h2>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="col mb-4">
            <div className="card custom-cards text-left h-100 linked">
              <Link to="/user-experience-consultant-services/">
                <div className="card-body">
                  <svg
                    className="text-primary mb-4"
                    height={50}
                    viewBox="0 0 144 144"
                    width={50}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      className="text-dark"
                    >
                      <rect
                        fill="none"
                        height="70.04"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        transform="translate(-0.07 0.06) rotate(-0.09)"
                        width="48.25"
                        x="17.49"
                        y="12.02"
                      />
                    </g>
                    <g
                      className="text-primary-light"
                    > 
                      <rect
                        fill="none"
                        height="38.91"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        transform="translate(-0.17 0.06) rotate(-0.09)"
                        width="48.25"
                        x="17.59"
                        y="94.51"
                      />
                    </g>
                    <g
                      className="text-primary-light"
                    >
                      <rect
                        fill="none"
                        height="38.91"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        transform="translate(-0.05 0.15) rotate(-0.09)"
                        width="48.25"
                        x="78.16"
                        y="11.93"
                      />
                    </g>
                    <g
                      className="text-dark"
                    > 
                      <rect
                        fill="none"
                        height="70.04"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        transform="translate(-0.15 0.15) rotate(-0.09)"
                        width="48.25"
                        x="78.26"
                        y="63.29"
                      />
                    </g>
                  </svg>
                  <h3>UI/UX consulting services</h3>
                  <p>
                    Give users an experience that feels intuitive and individualized, inspiring them to stick around longer.
                  </p>          
                </div>
              </Link>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards text-left h-100 linked">
              <Link to="/branding-design-logo-design/">
                <div className="card-body">
                  <svg
                    className="text-dark mb-4"
                    height={50}
                    viewBox="0 0 144 144"
                    width={50}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M17.81,42.14l0-24.06,24.06,0"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                      <path
                        d="M126.1,42l0-24.06L102,18"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                      <path
                        d="M17.9,102.3l0,24.06,24.06,0"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                      <path
                        d="M126.19,102.14l0,24.06-24.06,0"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                    </g>
                    <g className="text-primary-light">
                      <path
                        d="M15.85,72.23s24-32.12,56.1-32.17,56.2,32,56.2,32-24,32.12-56.1,32.17S15.85,72.23,15.85,72.23Z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                    </g>
                    <g>
                      <circle
                        cx="72"
                        cy="72.14"
                        fill="none"
                        r="16.66"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                    </g>
                  </svg>
                  <h3>Brand identity consulting services</h3>
                  <p>
                    Brand consulting is what turns a good brand into a stellar brand. In fact, it’s the secret of the world’s most successful companies.
                  </p>          
                </div>
              </Link>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards text-left h-100 linked">
              <Link to="/web-design-consulting-services/">
                <div className="card-body">
                  <svg
                    className="text-dark mb-4"
                    height={50}
                    viewBox="0 0 144 144"
                    width={50}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g className="text-primary-light">
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        x1="10.62"
                        x2="88.3"
                        y1="51.04"
                        y2="50.93"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        x1="26.95"
                        x2="35.12"
                        y1="36.08"
                        y2="36.07"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        x1="47.39"
                        x2="55.56"
                        y1="36.05"
                        y2="36.04"
                      />
                      <line
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        x1="27.06"
                        x2="116.98"
                        y1="111.7"
                        y2="111.57"
                      />
                    </g>
                    <g>
                      <path
                        d="M133.23,44.73l.13,83.79-122.62.18L10.58,22.43l106.34-.16"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                      <path
                        d="M71.65,67.61l49.28-49.36a7.23,7.23,0,0,1,10.22,0h0a7.22,7.22,0,0,1,0,10.21h0L81.88,77.81"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                      <path
                        d="M78.26,71.21a13.39,13.39,0,0,0-18.94,0h0c-5,5-5.71,24.7-5.71,24.7s19.65-.75,24.67-5.78a13.38,13.38,0,0,0,0-18.94Z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                      />
                    </g>
                  </svg>
                  <h3>Website Design Consulting Services</h3>
                  <p>
                    Whether your site just needs a refresh or must be built from the ground up, we provide comprehensive web design consulting services.
                  </p>          
                </div>
              </Link>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card custom-cards text-left h-100 linked">
              <Link to="/zendesk-guide-design/">
                <div className="card-body">
                  <StaticImage src="../images/partners/zendesk-guide.png" alt="Zendesk Guide" className="mb-4" height={50} />
                  <h3>Zendesk Guide Design Services</h3>
                  <p>
                    From ensuring your Help Center is accessed with a seamless experience to giving it a completely custom look and feel – we can help.
                  </p>          
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <h2>You deserve a true partner <span className="d-inline-block">– someone who’s always got your back</span></h2>
            <p>
              We’re confident you’ll choose 729 Solutions because we offer a few things that are hard to find anywhere else.
            </p>
            <CTA href="/contact-us/" text="BRING US YOUR CHALLENGES" className="btn btn-primary"/>  
          </div>
          <div className="col-md-6 order-0 order-md-1 pb-5 text-center">
            <StaticImage src="../images/backrounds/shaking-hands.jpg" alt="Shaking Hands" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <h3>Education</h3>
            <p>
              That’s right. We’ll help educate you about essential web issues like UI/UX, brand identity, web design principles, industry standards, and much more. We always want you to make an informed decision.
            </p>
          </div>
          <div className="col-lg-6 mb-4">
            <h3>Trust</h3>
            <p>
              We might joke around sometimes, but we take your trust seriously. We’ll deliver projects to your specs on time, within budget, and at the level of quality you expect.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <h3>Reputation</h3>
            <p>
              Look at our client portfolio and ask our previous customers what they think of us. You’ll hear all kinds of encouraging words, because our customers love us.
            </p>
          </div>
          <div className="col-lg-6 mb-4">
            <h3>Happiness</h3>
            <p>
              We’re all about delivering a positive experience for you, your internal customers, and your target audience.
            </p>
          </div>
        </div>
      </div>
    </section>

    <PortfolioFeed/>

    <section>
        <div className="container">
          <div className="row pt-6">
            <div className="col">
              <Quote
                quote="We started working with 729 at the earliest stages of Bundle and the team was integral in our Minimum Viable Product release."
                name="Eric Mager"
                company="Bundle Loans"
              />
            </div>
          </div>
        </div>
      </section>
  </Layout>
  );

  export default DesignService;
